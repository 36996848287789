.body-class{
  display: flex !important;
  flex-direction: column !important;
  min-height: 100vh !important;
  margin: 0 !important;
  background-color: #E1E4EA !important;
  color: #555 !important;
  font-size: 14px !important;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif !important;
}
.div-container{
  display: flex !important;
    flex-direction: row !important;
    /* flex-wrap: wrap !important; */
    justify-content: space-between !important;
    background-color: #F3F5F8 !important;
    padding: 7px 5px 2px 5px !important;
    display: flex !important;
    flex-direction: column !important;
    box-shadow: 0px 0px 6px #999 !important;
}
.title-header{
  position: relative;
    top: 12px;
    left: -15px;
}
.header-logo{
  height: 45px;
    width: 45px;
    margin: auto;
    border-radius: 50%;
    position: relative;
    left: -20px;
}
.footer-container {
  text-align: center;
  color: white;
  /* position: fixed; */
  background-color: rgb(52, 58, 64) !important;
  width: 100%;
  /* bottom: 0; */
}
.navbar-default{
  /* position: fixed; */
  top: 0;
  /* height: 60px !important; */
  width: 100%;
  z-index: 1000;
  background-color: #2B333E;
  box-shadow: 0px 0 8px 2px rgb(0 0 0 / 60%);
  margin: 0;
  padding: 8px 0;
  color: #FFF;
}
.navbar-default li{
 position: relative;
 top: 12px;
}
.bg-image-{
  /* background:url(./../img/299068_add_sign_icon.png) no-repeat !important; */
}
.footer-default{
  /* position: fixed; */
  color: white !important;
  bottom: 0;
  height: 60px !important;
  width: 100%;
  height: 80px;
  z-index: 1000;
  background-color: #2B333E;
  box-shadow: 0px 0 8px 2px rgb(0 0 0 / 60%);
  margin: 0;
  padding: 8px 0;
  color: #FFF;
}
.footer-default a{
  color: white;
  position:relative;
  top: 8px;;
}
.label-green-bold{
  font-size: 14px;
    color: #28a745;
    font-weight: bold;
    position: relative;
    top: 3px;
}
.div-abajo{
  position: fixed !important;
  bottom: 0 !important;
  width:100% !important;
  margin: auto;
}
.label-black-bold{
  font-size: 14px;
    color: black;
    font-weight: bold;
    position: relative;
    top: 3px;
}
.label-blue-bold{
  font-size: 14px;
    color: blue;
    font-weight: bold;
    position: relative;
    top: 3px;
}
.react-bootstrap-table .table{
  font-size: 12px;
}
.background-white-{
  background-color: unset !important;
}
.react-bootstrap-table .table td{
padding: 3px !important;
}
.containt-contentt{
  contain: content !important;
}
.scroll-ac{
  overflow: scroll !important;
}
.img-copy {
  float: right;
  width: 30px;
  cursor: pointer;
}
.img-copy:active {
  border-radius: 50%;
}
.w-145px{
  width: 145px;
}
.w-90px{
  width: 90px;
}
.w-300px {
  width: 300px;
}
.h-25px{
    height: 25px;
}
.w-70por {
  width: 70%;
}
.p-unset2{
    padding: 3px !important;
} 
.m-unset2{
    margin: unset !important;
}
.p-unset{
    padding: unset !important;
} 
.m-unset{
    margin: unset !important;
}
.header-cot {
  background-color: #00b050;
  font-size: 14px;
  font-weight: bold;
}
.ft-size14{
    font-size: 14px;
}
.cliente-div{
    position: relative;
    left: 15px;
}
.bg-total{
    background-color: #F2F2F2;
}
.bg-total2{
  background-color: #D1EFFA
}
.logo-school-informe {
    width: 110px;
    height: 110px;
    border-radius: 50%;
  margin: auto;
}
.w-15px{
    width: 15%;
}
.w-5p {
  width: 5%;
}

.w-55p {
  width: 55%;
}
.w-500px {
  width: 500px;
}
.w-250px {
  width: 250px;
}
.border-print .table thead th {
  /* border: unset !important; */
}
.btn-buscar {
  position: relative;
  top: 20px;
}
.btn-buscar-2 {
  position: relative;
  top: 28px;
}
.modal-save .modal-content {
  width: 300px !important;
}
.modal-xs .modal-content {
  width: 100% !important;
}
.modal-registration .modal-content {
  width: 800px !important;
}
/* .modal-content{
    width: 700px !important;
}
.modal-xl .modal-content{
    width: 900px !important;
} */
.pagination {
  float: right;
}
.img-status {
  width: 25px;
  height: 25px;
}
.img-search {
  left: -6px;
  position: relative;
  top: 4px;
  cursor: pointer;
  margin-left: 12px;
}
.editorClassName{
  background-color: white !important;
  height: 20vh !important;
  overflow-wrap: break-word !important;
}
.h-20vh{
  height: 30vh;
}
.pl-10px{
  padding-left: 10px !important;
}
.img-search-2 {
  left: 25%;
  position: absolute;
  top: -3px;
  cursor: pointer;
}
.img-search-page {
  width: 200px;
  cursor: pointer;
  /* height: 150px; */
}
.react-bootstrap-table-pagination-total {
  float: left;
}
.label-count-msg{
  position: relative;
    font-size: 10px;
    top: 5px;
}
.content-list-user {
  overflow: auto;
  height: 230px;
  max-height: 350px;
  min-height: 350px;
}
.text-red {
  font-weight: bold;
  color: red;
}
.modal-email{
  margin: auto;
  width: 30% !important;
  height: 130px !important;
}
.modal-email-content{
height: 200px !important;
}
.l-color-danger {
  font-weight: bold;
  font-size: 14px;
  color: red;
}
@media (min-width: 577px){
.modal-dialog {
    max-width: 80% !important;
    margin: 1.75rem auto;
}
.modal-dialog-password {
  max-width: 25% !important;
  margin: 1.75rem auto;
}
}

@media (min-width: 1050px){
  .scroll-ac{
    overflow:unset !important;
  }
  .navbar-default{
    height: 60px !important;
}

}
@media (max-width: 700px){
.visible-xs-logo{
display: none !important;
}
.pagination{
  position: relative;
  left: 70%;
}
.h-20vh{
  height: 70vh;
}
.rdw-editor-main {
  height: 320px !important;
}
}
