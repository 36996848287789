.user-login {
  width: 400px;
  margin: 0 auto;
}

.user-login__title {
  font-size: 40px;
  color: #0abde3;
  margin-bottom: 10px;
  text-align: center;
}

.user-login__error {
  color: red;
}

.login-logo {
  height: 100px;
  width: 100px;
  margin: auto;
  border-radius: 50%;
}

.user-login__submit-button {
  width: 100%;
  margin-top: 10px;
}
