.panel{
    margin-bottom: 0px;
}
.chat-window{
    bottom:0;
    /* position:fixed; */
    float:right;
    margin-left:10px;
}
.chat-window > div > .panel{
    border-radius: 5px 5px 0 0;
}
.icon_minim{
    padding:2px 10px;
}
.msg_container_base{
  /* background: #E5DDD5; */
  background-image: url("./../img/qwd83nc4xxf41.jpg");
  background-size: contain;
  margin: 0;
  padding: 0 10px 10px;
  height:55vh;
  overflow-x:hidden;
}
.top-bar {
  background: #777;
  color: white;
  padding: 10px;
  position: relative;
  overflow: hidden;
  border-radius: 5px 5px 0 0;
}
.label-name-chat{
    font-weight: bold;
    padding-left: 5px;
}
.red-label{
    border-radius: 50%;
    width: 37px;
    height: 32px;
    /* padding: 5px; */
    background: #fff;
    /* border: 2px solid #666; */
    color: white;
    text-align: center;
    /* font: 15px Arial, sans-serif; */
    background-color: red;
}
.list-person-icon{
    width: 40px;
    height: 40px;
    position: relative;
    /* left: 40%; */
    top: 0px;
}
.msg_receive{
    padding-bottom: 20px !important;
    padding-left:0;
    margin-left:0;
}
.msg_sent{
    background-color: #DCF8C6 !important;
    padding-bottom:20px !important;
    margin-right:0;
}
.messages {
    background: whitesmoke;
    padding: 2px;
    border-radius: 4px;
    box-shadow: 0 3px 6px rgb(0 0 0 / 29%);
    max-width: 100%;
}
.messages > p {
    font-size: 13px;
    margin: 0 0 0.2rem 0;
  }
.messages > time {
    font-size: 11px;
    color: #79886D;
}
.msg_container {
    padding: 5px;
    overflow: hidden;
    display: flex;
}
img {
    display: block;
    width: 100%;
}
.avatar {
    position: relative;
}
.base_receive > .avatar:after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: 0;
    height: 0;
    border: 5px solid #FFF;
    border-left-color: rgba(0, 0, 0, 0);
    border-bottom-color: rgba(0, 0, 0, 0);
}

.base_sent {
  justify-content: flex-end;
  align-items: flex-end;
}
.base_sent > .avatar:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 0;
    border: 5px solid white;
    border-right-color: transparent;
    border-top-color: transparent;
    box-shadow: 1px 1px 2px rgba(black, 0.2); 
}

.msg_sent > time{
    float: right;
}
.msg_receive > time{
    float: right;
}

.msg_container_base::-webkit-scrollbar-track
{
    box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    background-color: #F5F5F5;
}

.msg_container_base::-webkit-scrollbar
{
    width: 12px;
    background-color: #F5F5F5;
}

.msg_container_base::-webkit-scrollbar-thumb
{
    box-shadow: inset 0 0 6px rgba(0,0,0,.3);
    background-color: #555;
}

.btn-group.dropup{
    position:fixed;
    left:0px;
    bottom:0;
}
.icon-msg{
    width: 150px;
    position: relative;
    top: 0vh;
    margin: auto;
}
.icon-msg2{
    width: 150px;
    position: relative;
    top: 25vh;
    margin: auto;
}

.icon-msg-chat{
    width: 32px;
    margin-right: 20px;
}

@media only screen and (max-width: 600px) {
    .containerDocList{
        overflow: scroll;
        overflow-x: hidden;
        margin-top:10px;
        margin-bottom:10px;
        height:82vh;
    }
  }